import { isPlatformBrowser } from '@angular/common'
import { AppSettings } from '@configs/app.setting'
import { MerchantType } from '@models/common.model'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'
const ADMIN_PRIVILAGE: string = 'admin-privilage'
export function setAdminPrivilage(isAdmin: boolean) {
  localStorage.setItem(ADMIN_PRIVILAGE, JSON.stringify(isAdmin))
}
export function getAdminPrivilage(): any {
  let adminPrivilage: any = localStorage.getItem(ADMIN_PRIVILAGE)
  return (adminPrivilage = JSON.parse(adminPrivilage))
}
export function getEmail(): any {
  return localStorage.getItem(AppSettings.EMAIL)
}
export function getAuthKey(cookie: CustomCookieService): any {
  return cookie.getItem(environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV)
}
export function getAuthKeyWander(cookie: CustomCookieService): any {
  return cookie.getItem(environment.production ? AppSettings.AUTH_KEY_WANDER : AppSettings.AUTH_KEY_WANDER_DEV)
}
export function getMerchantCodeWander(cookie: CustomCookieService): any {
  return cookie.getItem(environment.production ? AppSettings.MERCHANT_CODE_WANDER : AppSettings.MERCHANT_CODE_WANDER_DEV)
}
export function isLoggedin(): any {
  return getAuthKey !== null && getEmail !== null
}
export function appVerChecker() {
  let result: boolean = false
  const localAppVersion = localStorage.getItem(AppSettings.APP_VERSION) || undefined
  const currAppVersion = environment.version
  if (localAppVersion !== undefined && localAppVersion === currAppVersion) {
    result = true
  } else {
    result = false
  }
  return result
}
export function getBusinessType(): string | any {
  let result: any = localStorage.getItem('merchant-business-type')
  return result
}

export function getUserInfo(platformId: Object): any {
  let isBrowser: boolean = isPlatformBrowser(platformId)
  if (isBrowser) {
    let result: any = localStorage.getItem(environment.production ? AppSettings.USER_INFO : AppSettings.USER_INFO_DEV)
    if (typeof result != 'undefined') {
      result = JSON.parse(result)
      return result
    } else {
      return false
    }
  }
}

export function setUserInfo(info: any) {
  localStorage.setItem(environment.production ? AppSettings.USER_INFO : AppSettings.USER_INFO_DEV, JSON.stringify(info))
}

export function email(email = '') {
  localStorage.setItem(AppSettings.EMAIL, email)
}

const cdSource = new BehaviorSubject<any>(1)
const emptyPasswordObserver = cdSource.asObservable()

export function setEmptyPassword(status: boolean) {
  let empty = status ? '1' : '0'
  localStorage.setItem(AppSettings.EMPTY_PASSWORD, empty)
  cdSource.next(1)
}
export function setAuthValidated(key: boolean, cookieService: CustomCookieService) {
  const isValidated: string = key === true ? 'true' : 'false'
  cookieService.setItem(AppSettings.AUTH_VALIDATED, isValidated)
}

export function setBusinessName(businessName: string) {
  localStorage.setItem(AppSettings.BUSINESS_NAME, businessName)
}

export function setNameInitial(name: string) {
  localStorage.setItem(AppSettings.NAME_INITIAL, name.charAt(0))
}

export function setAuthKey(key: string, cookieService: CustomCookieService) {
  // this._cookieService.put(AppSettings.AUTH_KEY, key)
  cookieService.setItem(environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV, key)
}

export function setSockToken(token: string, cookieService: CustomCookieService) {
  cookieService.setItem(AppSettings.TOKEN_WEB_SOCKET, token)
}

export function setNewUser(cookieService: CustomCookieService, isNew: boolean) {
  cookieService.setItem(AppSettings.NEW_USER, isNew.toString())
}

export function setAskGeneralInfo(isSet: boolean) {
  const _isSet = isSet == true ? 1 : 0
  localStorage.setItem(AppSettings.MERCHANT_ASK_GENERAL_INFO, JSON.stringify(_isSet))
}

export function reValidateAccount(cookieService: CustomCookieService, platformId: Object): boolean {
  let info: any = getUserInfo(platformId)

  if (info) {
    email(info.email)
    setEmptyPassword(info.emptyPassword)
    setAuthValidated(info.validated, cookieService)
    setBusinessName(info.name)
    setNameInitial(info.name)
    setAuthKey(info.authKey, cookieService)
    setSockToken(info.socketToken, cookieService)
    setNewUser(cookieService, false)
    setAskGeneralInfo(info.basicIdentityNotComplete)
    return true
  } else {
    return false
  }
}

export function setUserActivated(cookieService: CustomCookieService, isActive: boolean) {
  if (typeof isActive !== 'boolean') isActive = false
  cookieService.setItem(AppSettings.COOKIE_USER_ACTIVATED, JSON.stringify(isActive))
}
export function getUserActivated(cookieService: CustomCookieService): string | boolean | null {
  let result: string | boolean | null | undefined = cookieService.getItem(AppSettings.COOKIE_USER_ACTIVATED)!
  if (typeof result !== 'string') return false
  return JSON.parse(result)
}

export function setMerchantType(type: MerchantType) {
  if (typeof type === 'undefined') return
  localStorage.setItem(AppSettings.MERCHANT_TYPE, JSON.stringify(type))
}

export function getMerchantType(): MerchantType | undefined {
  let result: string | MerchantType | null | undefined = localStorage.getItem(AppSettings.MERCHANT_TYPE)!
  if (typeof result !== 'string') return undefined
  return JSON.parse(result)
}

export function setLastLogin(businessName: string) {
  localStorage.setItem(AppSettings.MERCHANT_LAST_LOGIN, businessName)
}

export function getLastLogin() {
  return localStorage.getItem(AppSettings.MERCHANT_LAST_LOGIN)
}
