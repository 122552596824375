import { isPlatformBrowser } from '@angular/common'
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core'
import { setTimezoneList } from '@core/utils/app-stuff'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { CommonService } from '@services/common/common.service'
import { ViewService } from '@services/view/view.service'

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  template: `
    <!-- <app-error-page [class.d-none]="!setErrorPage"></app-error-page> -->
    <router-outlet *ngIf="isBrowser"></router-outlet>
    <ngx-spinner type="ball-scale-multiple" color="#00489A" size="medium"></ngx-spinner>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit, AfterContentChecked {
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private _viewService: ViewService,
    private _commonService: CommonService,
    private _commonRepository: CommonRepositoryService
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
    this.errorPageListener()
  }

  setErrorPage: boolean = this._viewService.getErrorPage() || false
  title = 'ideku'
  deviceInfo: any = null
  isBrowser: boolean = false

  ngOnInit(): void {
    this.setTimezoneList()
  }

  ngAfterContentInit(): void {}

  ngAfterContentChecked(): void {}

  errorPageListener() {
    this._commonService.errorPage.subscribe((response) => {
      this.setErrorPage = this._viewService.getErrorPage() || false
    })
  }

  setTimezoneList() {
    this._commonRepository.timezoneList().subscribe((response) => {
      if (response.status === 200) {
        setTimezoneList(response.data)
      }
    })
  }

  // setScreenView() {
  //   // Check Screen View
  //   const styleToCheck = '(min-width: 800px)'
  //   //Listen to changes in screen width
  //   this.bpo.observe([styleToCheck]).subscribe((result) => {
  //     if (result.matches) {
  //       this.screenView = VIEW_MODE_DESKTOP
  //     } else {
  //       this.screenView = VIEW_MODE_MOBILE
  //     }
  //   })
  // }
}
