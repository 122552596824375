import { AppSettings } from "@configs/app.setting"

export const AdminStorageList = {
    BUSINESS_PAGINATION: 'business-pagination',
    BUSINESS_MONITORED_INFO: 'business-monitored-info',
    COOKIE_SA_COUNTRY: AppSettings.COOKIE_SA_COUNTRY,
    COOKIE_SA_CURRENCY: AppSettings.COOKIE_SA_CURRENCY,
    COOKIE_SA_CURRENCY_LABEL: AppSettings.COOKIE_SA_CURRENCY_LABEL,
    COOKIE_SA_CURRENCY_SYMBOL: AppSettings.COOKIE_SA_CURRENCY_SYMBOL,
    phoneCode: AppSettings.phoneCode,
    TIMEZONE_LIST: AppSettings.TIMEZONE_LIST,
    EMAIL: AppSettings.EMAIL,
    BUSINESS_NAME: AppSettings.BUSINESS_NAME,
    ROLE_NAME: AppSettings.ROLE_NAME,
    ADMIN_PRIVILAGE: AppSettings.ADMIN_PRIVILAGE,
    CURRENT_MENU_LIST: AppSettings.CURRENT_MENU_LIST,
    CURRENT_ADMIN_INIT_MENU_LIST: AppSettings.CURRENT_ADMIN_INIT_MENU_LIST,
    CURRENT_INIT_MENU_LIST: AppSettings.CURRENT_INIT_MENU_LIST,
    BUSINESS_TYPE: AppSettings.BUSINESS_TYPE,
    APP_VERSION: AppSettings.APP_VERSION,
    NAME_INITIAL: AppSettings.NAME_INITIAL
}