import { AppSettings } from '@configs/app.setting'
import { AdminBusinessDetail } from '@core/models/admin-business.model'

const BUSINESS_MONITORED_INFO: string = 'business-monitored-info'
const BUSINESS_LOGO_URL: string = 'business-logo-url'

export function setMonitoredBusiness(businessInfo: AdminBusinessDetail | null) {
  localStorage.setItem(BUSINESS_MONITORED_INFO, JSON.stringify(businessInfo))
}

export function getMonitoredBusiness(): any {
  let result: any = localStorage.getItem(BUSINESS_MONITORED_INFO)
  return JSON.parse(result)
}
export function destroyMonitoredBusiness() {
  localStorage.setItem(BUSINESS_MONITORED_INFO, JSON.stringify(null))
}

export function setBusinessName(businessName: string) {
  localStorage.setItem(AppSettings.BUSINESS_NAME, businessName)
}

export function getBusinessName(): string | undefined {
  return localStorage.getItem(AppSettings.BUSINESS_NAME) || undefined
}

export function setRoleName(roleName: string) {
  localStorage.setItem(AppSettings.ROLE_NAME, roleName)
}

export function getRoleName() {
  return localStorage.getItem(AppSettings.ROLE_NAME)
}

export function setBusinessLogo(url: string) {
  if (typeof url === 'undefined') {
    localStorage.setItem(BUSINESS_LOGO_URL, JSON.stringify(''))
  } else {
    localStorage.setItem(BUSINESS_LOGO_URL, JSON.stringify(url))
  }
}

export function getBusinessLogo(): string {
  let result: string | undefined = localStorage.getItem(BUSINESS_LOGO_URL) || undefined
  if (typeof result === 'undefined') {
    return ''
  }

  return JSON.parse(result)
}
