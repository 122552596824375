import { AppSettings } from '@configs/app.setting'
import { environment } from 'src/environments/environment'

export const COOKIE_NAME_LIST: string[] = [
  AppSettings.EMAIL,
  AppSettings.EMPTY_PASSWORD,
  AppSettings.AUTH_VALIDATED,
  AppSettings.BUSINESS_NAME,
  AppSettings.NAME_INITIAL,
  environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV,
  AppSettings.TOKEN_WEB_SOCKET,
  AppSettings.NEW_USER,
  AppSettings.MERCHANT_ASK_GENERAL_INFO,
  environment.production ? AppSettings.USER_INFO : AppSettings.USER_INFO_DEV,
  AppSettings.NEW_USER,
  AppSettings.ONBOARD_ACCESS,
  AppSettings.COOKIE_LANGUAGE,
  AppSettings.COOKIE_CURRENCY,
  AppSettings.COOKIE_CURRENCY_LABEL,
  AppSettings.COOKIE_COUNTRY,
  AppSettings.COOKIE_CURRENCY_SYMBOL,
  AppSettings.COOKIE_USER_ACTIVATED,
  environment.production ? AppSettings.AUTH_KEY_WANDER : AppSettings.AUTH_KEY_WANDER_DEV,
]
