import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
// import * as Sentry from '@sentry/angular-ivy'

// Sentry.init({
//   dsn: environment.sentryUrl,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: environment.sentrySampleRate, // Capture 100% of the transactions, reduce in production!
// })

if (environment.production) {
  enableProdMode()
}

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error('platformBrowserDynamic Error. ', err))

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
})

// Register IDR locale currency
import { registerLocaleData } from '@angular/common'
import localeID from '@angular/common/locales/id'
registerLocaleData(localeID, 'id')
