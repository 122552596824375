import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { JobQueueCheck } from '@core/models/common.model'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAuthKey } from '@core/utils/auth-stuff'
import { Observable } from 'rxjs'
import { tap, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class CommonRepositoryService {
  constructor(
    private _httpClient: HttpClient,
    protected appGuard: AppGuard,
    private _authService: AuthService,
    private _customCookieService: CustomCookieService
  ) {}

  connectionTest(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient.get(AppSettings.CONNECTION_TEST_URL, { headers: headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.appGuard.checkAccess(err.status)
          }
        },
      })
    )
  }

  countryList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_COUNTRY}`, { headers: headers })
      .pipe(
        map((response: any) => {
          // response.data = response.data.filter(country => country.availableStore && country.code !== 'PH')
          return response
        }),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  currencyList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_CURRENCY}`, { headers: headers })
      .pipe(
        map((response: any) => {
          // response.data = response.data.filter(currency => currency.code !== 'PHP')
          return response
        }),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  timezoneList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_TIMEZONE}`, { headers: headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  phoneCountryCodeList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_PHONE_COUNTRY_CODE}`, { headers: headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  businessTemplateList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_BUSINESS_TEMPLATE}`, { headers: headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  businessTierList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_BUSINESS_TIER}`, { headers: headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.appGuard.checkAccess(err.status)
            }
          },
        })
      )
  }

  /**
   * Download
   */

  checkDownloadRequest(checkToken: string): Observable<JobQueueCheck | any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    return this._httpClient
      .get(`${AppSettings.JOB_QUEUE_URL}/${AppSettings.JOB_QUEUE_CHECKING}/${checkToken}`, {
        headers: headers,
      })
      .pipe(
        map((response: JobQueueCheck | any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  downloadProduct(downloadToken: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', '*/*')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    headers = headers.set('Content-Type', 'application/octet-stream')

    return this._httpClient
      .get(`${AppSettings.JOB_QUEUE_URL}/${AppSettings.JOB_QUEUE_DOWNLOAD}/${downloadToken}`, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe(
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  checkRegion(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this._httpClient
      .get('check-region', {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  urlChecker(url: string): Observable<number> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient.post('url-checker', {url: url}, {headers: headers})
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          }
        })
      )
  }
}
